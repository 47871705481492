import React, { useContext } from 'react';
import { GlobalStyles } from '../components/layout/GlobalStyles';
import { Nav } from '../components/nav/Nav';
import { siteContext } from './SiteContextProvider';
import { Map } from '../components/Map';
import { ContactForm } from '../components/Contact-Form';
import { Footer } from '../components/Footer';
import { Hours } from '../components/Hours';
import { InsurancePanel } from '../components/Insurance-Panel';
import { BluePanel } from '../components/Blue-Panel';
import { FormPanels } from '../components/Form-Panels';
import { MDXProvider } from '@mdx-js/react';

const shortcodes = { BluePanel, Hours, FormPanels };

export const AltLayout: React.FC = ({ children }) => {
    const { setDesktopActive } = useContext(siteContext);
    return (
        <div id="layout">
            <Nav />
            <GlobalStyles />
            <div onMouseEnter={() => setDesktopActive(false)}>
                <MDXProvider components={shortcodes}>{children}</MDXProvider>

                <InsurancePanel />
                <Hours />
                <Map />
                <ContactForm />
                <Footer />
            </div>
        </div>
    );
};
