import React from 'react';
import styled from '@emotion/styled';
import { colors, device } from '../components/layout/GlobalStyles';
import { Seo } from '../components/Seo';
import { AltLayout } from '../layout/AltLayout';
import { Button } from '../components/layout/StyledComponents';
import { Link } from 'gatsby';
import {  css } from '@emotion/react';

const StyledMDXBody = styled.div`
    padding: 108px 20px 0px;
    a {
        color: ${colors.blueMedium};
        &:hover {
            text-decoration: underline;
        }
    }
    p {
        font-family: 'lato';
    }
    h2 {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        color: ${colors.blueMedium};
    }
    @media ${device.tablet} {
        max-width: 750px;
        padding: 108px 20px 0px;
        margin: 0 auto;
        h2 {
            margin-top: 2em;
        }
    }
    @media ${device.laptopS} {
        max-width: 750px;
        margin: 50px auto 200px;
        padding-top: 32px;

        /* main service pictures */
        h2 {
            margin-top: 60px;
        }
        p {
            width: 750px;
        }
    }
    @media ${device.laptop} {
        max-width: 846px;
    }
    @media ${device.laptopL} {
        max-width: 1046px;
        /* main service pictures */
        p {
            width: 1046px;
        }
    }
`;

const PrivacyPolicy: Page = () => {
    return (
        <>
            <Seo
                title="Privacy Policy | Woodmor Family Dentistt"
                description="We recognize that you may be concerned about our use and disclosure of your personal information. Your privacy is very important to us"
            />

            <StyledMDXBody>
                <h2>Privacy Policy</h2>
                <p>
                    We recognize that you may be concerned about our use and disclosure of your
                    personal information. Your privacy is very important to us, and the following
                    will inform you of the information that we, Woodmoor Family Dentistry, may
                    collect from you, and how it is used. By using our website,
                    <a href="https://www.themonumentdentist.com/" target="_blank" rel="noreferrer">
                        {' '}
                        www.themonumentdentist.com{' '}
                    </a>
                    you are accepting the practices described in this policy.
                </p>
                <h2>Information Collection</h2>
                <p>
                    We may collect non-personal information, such as a domain name and IP Address.
                    The domain name and IP address reveals nothing personal about you other than the
                    IP address from which you have accessed our site. We may also collect
                    information about the type of Internet browser you are using, operating system,
                    what brought you to our Website, as well as which of our Web pages you have
                    accessed.
                </p>
                <p>
                    Additionally, if you communicate with us regarding our Website or our services,
                    we will collect any information that you provide to us in any such
                    communication.
                </p>{' '}
                <p>
                    We may contact you via email in the future to tell you about specials, new
                    products or services, or changes to this privacy policy.
                </p>{' '}
                <h2>Information Use</h2>
                <p>
                    We use the collected information primarily for our own internal purposes, such
                    as providing, maintaining, evaluating, and improving our services and Website,
                    fulfilling requests for information, and providing customer support.
                </p>
                <h2>Consent </h2>
                <p>
                    By using this Website, you consent to the collection and use of information as
                    specified above. If we make changes to our Privacy Policy, we will post those
                    changes on this page. Please review this page frequently to remain up-to-date
                    with the information we collect, how we use it, and under what circumstances we
                    disclose it. You must review the new Privacy Policy carefully to make sure you
                    understand our practices and procedures.{' '}
                </p>
                <p style={{ fontWeight: '700' }}>
                    If you feel that we are not abiding by this privacy policy, you should contact
                    us immediately via telephone at (719) 488-3014 or via mail Attn: Privacy
                    Officer, 1777 Lake Woodmoor Drive, Monument, CO 80132.
                </p>
                <Button as="a" href='/NPP-Woodmoor.pdf' rel="noopener noreferrer" target="_blank" color="blue" style={{
                    background: 'transparent',
                    marginTop: '32px',
                    maxWidth: 'max-content',
                }}>Notice of Privacy Pracitices Document</Button>
            </StyledMDXBody>
        </>
    );
};

PrivacyPolicy.Layout = AltLayout;

export default PrivacyPolicy;
